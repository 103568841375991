<template>
    <div>        
        <div class="body-w100" >
            <div class="box-detail" > 
                <ModalConfigColumn :page="page" :type="type" :column="orderColumn" :reset_column="reset_column"  />
                <IconTopTable @onRequest="request_data" :page="page" @onSelectExport="selectExport" @onClearExport="clearExport" />
                <div class="content-table-results mt-2">
                    <table class="table table-fixed table-stripes" >
                        <ColumnColGroup :page="page" :column="orderColumn" :default_column="default_column" />
                        <ColumnThead :page="page" :column="orderColumn" :default_column="default_column" /> 
                        
                        <tbody>    
                            <template v-if="data.data && data.data.length > 0">        
                            <template v-for="(item, key) in data.data" >             
                            
                            <tr v-for="(sub_item, sub_key) in max_row(item)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)" > 
                                
                                <td>                                    
                                    <label class="table-check" v-if="sub_key===0">
                                        <input type="checkbox" v-model="select_export" :value="item.id">
                                        <span class="checkbox-table"></span>
                                    </label>
                                </td>
                                <td>
                                    <router-link v-if="sub_key===0" :to="'/detail/'+item.drug_id+'/'+link_detail_url(item.agency_model)" target="_blank" rel="noopener">
                                        <div class="icon-fiche"></div>
                                    </router-link>
                                    <div v-if="sub_key===0 && isAdmin()">
                                        <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                            <em class="icon-edit"></em>
                                        </a>
                                    </div>
                                </td>
                                
                                <template v-for="(column) in orderColumn" >                                               
                                    
                                    <td v-if="column.Status && column.code == 'report_022'" :key="column.code" :code="column.code"> 
                                         <Readmore v-if="sub_key===0" @click="essais_clinique_popup(item.drug_id, item.agency_model)" :longText="nct_number(item.nct_numbers)" :textAlign="'center'"/>                                                    
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'report_001'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">{{ check_empty(item.name)}}</template>             
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_002'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">{{ check_empty(item.dci)}}</template> 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_003'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">{{ check_empty(item.drug_id)}}</template>                                               
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_004'" :key="column.code" :code="column.code">                                                 
                                        <span v-if="sub_key===0" v-html="ema_id(item.links)"></span>                                         
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_005'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.ma_date)) }}</template> 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_006'" :key="column.code" :code="column.code">  
                                        <template v-if="sub_key===0">                                               
                                        <span v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                        <span v-else v-html="check_empty(array(item.classification_en))"></span>     
                                        </template>                                         
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_007'" :key="column.code" :code="column.code">                                                 
                                         <span v-if="sub_key===0 && item['indexation']" v-html="check_empty(array(item['indexation']))"></span>                                            
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_008'" :key="column.code" :code="column.code">                                                 
                                        <Readmore v-if="sub_key===0" :longText="item.indication_en"/>                                            
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_009'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">{{item.country_name}}</template>                                                
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_010'" :key="column.code" :code="column.code">
                                        <div v-if="sub_key===0"><img :alt="item.agency_model" :src="require('@/assets/images/flag/new/'+item.icon_flags+'.svg')" width="16" height="16" style="margin-right: 5px;" />  {{item.agency_name}}</div>     
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_011'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">                                            
                                            {{ item.agency_type.indexOf("HTA") !== -1 ? check_empty(DDMMMYY(item.hta_process_date)) : "-"}}                                           
                                        </template>                                             
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_012'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="item.view_report && item.view_report.population && item.view_report.population[sub_key]">
                                            <template  v-if="item.agency_model === 'Transparence'" >
                                                <Readmore v-if="$i18n.locale === 'fr'" :longText="item.view_report.population[sub_key]['smr_title']"/>
                                                <Readmore v-else :longText="item.view_report.population[sub_key]['smr_title_en']"/>
                                            </template>
                                            <template v-else-if="item.agency_model === 'Gba' && item.view_report.population[sub_key] !== '' " >
                                                <Readmore v-if="$i18n.locale === 'de'" :longText="item.view_report.population[sub_key]['titlede']"/>
                                                <Readmore v-else :longText="item.view_report.population[sub_key]['title']" />   
                                            </template>
                                            <template v-else>
                                                <Readmore :longText="item.view_report.population[sub_key]" />
                                            </template>
                                        </template>    
                                        <span v-else> - </span>                         
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_013'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="item.view_report && item.view_report.hta_decision && item.view_report.hta_decision[sub_key]">
                                            <template  v-if="item.agency_model === 'Transparence'">                                                
                                                <span v-html="smr_asmr_type(item.view_report.hta_decision[sub_key])"></span> 
                                            </template>
                                            <template v-else-if="item.agency_model === 'Gba' && item.view_report.hta_decision[sub_key] !== '' " >
                                                <span :class="color_hta_decision(item.view_report.hta_decision[sub_key], item.agency_model)">{{ check_empty_with_locale(item.view_report.hta_decision[sub_key], 'gba_added_benefit_id_') }}</span> 
                                            </template>
                                            <template v-else>
                                                <span :class="color_hta_decision(item.view_report.hta_decision[sub_key])">{{ check_empty(item.view_report.hta_decision[sub_key]) }}</span> 
                                            </template>
                                        </template>    
                                        <span v-else> - </span>                                         
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_014'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.rembursement_process_date)) }}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_015'" :key="column.code" :code="column.code">          
                                        <template v-if="sub_key===0">                                       
                                            <template  v-if="['Nice', 'Italy', 'Smc', 'Spain'].includes(item.agency_model)" >
                                                <span v-html="rembursement_status(item.reimbursement_decision, 'rb_')"></span>    
                                            </template>
                                            <template v-else-if="['Aotmit', 'Tlv', 'Zinl', 'Ncpe', 'Cadth', 'Perc', 'Pbac', 'Pmda', 'Awmsg', 'Colombia', 'Singapore', 'Nihta'].includes(item.agency_model)" >
                                                <span v-html="rembursement_status(item.reimbursement_decision, 'rb2_')"></span>
                                            </template>   
                                            <template v-else-if="['Gba'].includes(item.agency_model)" >
                                                <span v-html="rembursement_status(item.reimbursement_decision, 'gba.rb_')"></span>
                                            </template>
                                            <template v-else-if="['Transparence'].includes(item.agency_model)" >
                                                <span v-html="rembursement_status(item.reimbursement_decision, 'rembursement_status.tc_')"></span>
                                            </template>
                                            <template v-else>
                                                <span v-html="rembursement_status(item.reimbursement_decision)"></span>
                                            </template>     
                                        </template>                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_016'" :key="column.code" :code="column.code">                                                 
                                        <span v-if="sub_key===0" :class="color_type_limitation(item.prioritis_hta_index)">{{check_empty(item.prioritis_hta_index)}}</span>                                             
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_017'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">
                                            <Readmore v-if="['en', 'de'].includes($i18n.locale) && item['target_population_'+$i18n.locale]" :longText="item['target_population_'+$i18n.locale]"/>
                                            <Readmore v-else :longText="item['target_population']"/>
                                        </template>                                             
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_018'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">
                                            <Readmore v-if="['en', 'de'].includes($i18n.locale) && item['prescription_and_delivery_conditions_'+$i18n.locale]" :longText="item['prescription_and_delivery_conditions_'+$i18n.locale]"/>
                                            <Readmore v-else :longText="item['prescription_and_delivery_conditions']"/>
                                        </template>                                               
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_019'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">
                                            <Readmore v-if="['en', 'de'].includes($i18n.locale) && item['access_scheme_'+$i18n.locale]"  :longText="item['access_scheme_'+$i18n.locale]"/>
                                            <Readmore v-else :longText="item['access_scheme']"/>  
                                        </template>                                            
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'report_020'" :key="column.code" :code="column.code">                                                 
                                        <template v-if="sub_key===0">
                                            <Readmore v-if="['en', 'de'].includes($i18n.locale) && item['outcome_level_'+$i18n.locale]"  :longText="item['outcome_level_'+$i18n.locale]"/>
                                            <Readmore v-else :longText="item['outcome_level']"/>  
                                        </template>                                             
                                    </td>                                         
                                    <td v-else-if="column.Status && column.code == 'report_021'" :key="column.code" :code="column.code">                                                 
                                        <Readmore v-if="sub_key===0" :longText="item['rationale_and_comments_html_en']"/>                                                
                                    </td> 
                                                                      
                                </template>
                            </tr>

                            </template>
                            </template>

                            <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>
                           
                        </tbody>

                    </table>
                </div>
                <!---- Area Table Result End----->
                    
            </div>
        </div>

        <teleport to="body">
        <div class="modal fade gray-box box-config-adv-column" id="modal-filter-report" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-body">

                        <div class="row">
                            <a type="button" class="close" style="opacity: 1;" data-bs-dismiss="modal" aria-label="Close">
                                <span  class="close-column"></span>
                            </a>
                            <div class="col-sm">
                                
                                <div class="title-report-filter">BRAND NAME</div>
                                <div  class="box-border-gray-collapse" >  
                                    <label class="group-checkbox" style="cursor: pointer;margin:0">
                                        
                                        <!-- <input type="checkbox" v-model="selectAll[column.key]" @click="onSelectAll(column.key)"> -->
                                        <input type="checkbox"  v-model="select_all_name" >
                                        <span class="icon-checkbox"></span>
                                    </label>
                                    <div class="text-title-collapse" data-bs-toggle="collapse" data-bs-target="#box_name" role="button" aria-expanded="false" aria-controls="box_name">
                                        {{$t('check_uncheck')}}<span class="bt-icon-down"><em class="fa fa-chevron-down box_name"></em> </span>
                                    </div>
                                    <div id="box_name" class="mt-2 collapse">  
                                    <li v-for="(item, key) in all_name" :key="key" class="list-li-item" >     
                                        <label class="group-checkbox" style="cursor: pointer;">                                
                                            <input type="checkbox" v-model="item_filter_name" :value="item" :id="'name_'+key" >
                                            <span class="icon-checkbox" ></span>
                                            <div>{{item}}</div> 
                                            
                                        </label>
                                    </li>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm">
                                <div class="title-report-filter">THERAPEUTIC AREA</div>
                                <div  class="box-border-gray-collapse" >  
                                    <label class="group-checkbox" style="cursor: pointer;margin:0">                             
                                        <!-- <input type="checkbox" v-model="selectAll[column.key]" @click="onSelectAll(column.key)"> -->
                                        <input type="checkbox" v-model="select_all_indexation" >
                                        <span class="icon-checkbox"></span>
                                    </label>
                                    <div class="text-title-collapse" data-bs-toggle="collapse" data-bs-target="#box_indexation" role="button" aria-expanded="false" aria-controls="box_indexation">
                                        {{$t('check_uncheck')}} <span class="bt-icon-down"><em class="fa fa-chevron-down box_indexation"></em> </span>
                                    </div>
                                    <div id="box_indexation" class="mt-2 collapse">  
                                    <li v-for="(item, key) in all_indexation" :key="key" class="list-li-item" >     
                                        <label class="group-checkbox" style="cursor: pointer;">
                                            
                                            <input type="checkbox" v-model="item_filter_indexation" :value="item" :id="'name_'+key" >
                                            <span class="icon-checkbox" ></span>
                                            <div>{{item}}</div> 
                                            
                                        </label>
                                    </li>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm">
                                <div class="title-report-filter">COUNTRIES</div>
                                <div  class="box-border-gray-collapse" >  
                                    <label class="group-checkbox" style="cursor: pointer;margin:0">
                                        
                                        <!-- <input type="checkbox" v-model="selectAll[column.key]" @click="onSelectAll(column.key)"> -->
                                        <input type="checkbox" v-model="select_all_agency">
                                        <span class="icon-checkbox"></span>
                                    </label>
                                    <div class="text-title-collapse" data-bs-toggle="collapse" data-bs-target="#box_agency" role="button" aria-expanded="false" aria-controls="box_agency">
                                        {{$t('check_uncheck')}} <span class="bt-icon-down"><em class="fa fa-chevron-down box_agency"></em> </span>
                                    </div>
                                    <div id="box_agency" class="box_report_agency mt-2 collapse">  
                                        <span v-for="(item,key) in groupAgency" :key="key" >
                                            <label  v-if="Object.keys(item).length > 0" class="label-group_country">{{key}}</label>   

                                            <li v-for="(item_data, key_data) in item" :key="'agency-'+key_data" class="list-checkbox">
                                                <div style="vertical-align: middle; margin-top: 5px;">
                                                    <label>
                                                        <input type="checkbox" :value="key_data" v-model="item_filter_country" name="agency" @click="activeAgency(key_data, item_data.agency)" >
                                                        <span class="check-therapeutic" style="margin-top: 0px;"></span> 
                                                        <img :alt="key_data" :src="require('@/assets/images/flag/new/'+item_data.icon_flags+'.svg')" width="20" height="20" style="margin-right: 5px;" />
                                                        <div style="margin-left: 0px; top:0px;">{{key_data}}</div>
                                                    </label>
                                                </div>
                                            </li>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div class="bt-footer">
                            <button type="button" class="btn-orange" @click="request_data"  >{{$t('Apply filters')}}</button>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        </teleport>
        <ModalEssaisClinique :id="drug_id" :agency="agency" />
        
    </div>
    
                 
</template>

<script>
import { Modal } from 'bootstrap'
import IconTopTable from '@/components/elements/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import ModalConfigColumn from '@/components/elements/modal_config_column.vue';
import Readmore from '@/components/elements/readmore.vue';
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue';
import { currentDate, link_detail_url, isAdmin, modifyPage, rembursement_status, array, ema_id, DDMMMYY,
    check_empty, color_type_limitation, color_hta_decision, check_empty_with_locale, odd_even_border, nct_number } from '@/utils';
import { decode } from 'js-base64';
export default {
    name : "TabReport",
    components: {    
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        ModalConfigColumn,
        Readmore,
        ModalEssaisClinique
    },
    
    data() {
        return {
            page : "report", 
            item_filter_name : [],
            item_filter_indexation : [],
            item_filter_agency : [],
            item_filter_country : [],
            default_agency : ['Nice', 'Transparence', 'Gba', 'Italy', 'Spain'],
            modal : '',
            drug_id : 0,  //for essais_clinique popup
            agency : '' //for essais_clinique popup
        }
    },
    mounted() {
        console.log(' load tab report...........')
        //search
        if(Object.keys(this.data).length === 0 && this.data.constructor === Object){

            let keyword_decode = decode(this.$route.params.base64Search);            
            let keyword_arr = keyword_decode.split("|");
            let keyword_agency = []
            keyword_arr.forEach(function (value) {                 
                let temp_arr = value.split("=");
                if(temp_arr && temp_arr[0] && temp_arr[0] === "agency"){
                    keyword_agency = temp_arr[1].split("#");
                }
            });

            if(keyword_agency.length === 0){                
                this.item_filter_agency = this.default_agency
            } else {
                this.item_filter_agency = keyword_agency
            }

            this.request_data() 
            this.modal = new Modal(document.getElementById('modal-filter-report'));
        }
    },     
    methods: { 
        request_data(param = {}){
            let paramObj = {...this.$route.params, ...this.$route.query}
            paramObj['base64Search'] = paramObj['base64Search'].replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line
            if(param.page){
                paramObj['page'] = param.page
            }
            if(this.all_name.length !== this.item_filter_name.length){
                paramObj['filter_name'] = this.item_filter_name;
            }
            if(this.all_indexation.length !== this.item_filter_indexation.length){
                paramObj['filter_indexation'] = this.item_filter_indexation;
            }
            if(this.item_filter_agency.length > 0){
                paramObj['filter_agency'] = this.item_filter_agency;
            }
            if(this.select_export_obj.isSelect){
                paramObj['report_select_export'] = this.select_export_obj.listID
            } 
            if(param.export){
                delete param.export;
                this.$store.dispatch(`report/result_report_excel`, [paramObj, currentDate()+"_report" ] ).then(()=> {
                    this.modal.hide();
                });  
            }else{
                this.$store.dispatch(`report/result_report`, paramObj).then(()=> {
                    this.modal.hide();
                });  
            } 
        },
        selectExport(){
            this.$store.dispatch("report/select_export", { key : "isSelect" , value : true})                     
            this.request_data() 
        },
        clearExport(){
            this.$store.dispatch("report/select_export", "clear")                     
            this.request_data()
        },
        max_row(param){
            const temp = []
            temp.push(1)         
           
            if(param.view_report && param.view_report.hta_decision){
                temp.push(param.view_report.hta_decision.length)
            }
            if(param.view_report && param.view_report.population){
                temp.push(param.view_report.population.length)
            }
            
            return Math.max(...temp)
        },
        smr_asmr_type(param) {
            let txt = ''
            let color = ''
            
            if (param && param.smr_type) {
                let txt2 = this.$t('transparence.smr.' + param.smr_type)
                color = 'green'
                if (param.smr_type === "insuffisant") {
                    color = 'red'
                }
                if (param.smr_type === "Remboursement non sollicité par la firme") {
                    color = ''
                }

                if(param.asmr_obtenue && param.asmr_obtenue !== ""){
                    txt2 += " / " + this.$t('transparence.asmr.'+param.asmr_obtenue) 
                }
                txt = `<span class="${color}">${txt2}</span>`
            }
            txt = this.check_empty(txt)
            return txt
        },
        modifyPage,
        isAdmin,
        rembursement_status,
        array,
        ema_id,
        DDMMMYY,
        check_empty,
        color_type_limitation,
        color_hta_decision,
        check_empty_with_locale,
        odd_even_border,
        link_detail_url,
        nct_number,
        essais_clinique_popup(id, agency){
            this.drug_id = id,
            this.agency = agency
        },
        activeCollapsed(event) {
            var str = event.target.className;
            let arrow;
            if( str.includes("not-collapsed")) {
                arrow = document.getElementsByClassName(event.target.getAttribute('box_key'));
                arrow[0].className = arrow[0].className.replace(/fa-chevron-up/g, "fa-chevron-down");
            }else{
                arrow = document.getElementsByClassName(event.target.getAttribute('box_key'));
                arrow[0].className = arrow[0].className.replace(/fa-chevron-down/g, "fa-chevron-up");
            }
        },
        activeAgency(country, agency){
            const index_country = this.item_filter_country.indexOf(country);
            if (index_country > -1) {
                this.item_filter_country.splice(index_country, 1);
            } else if (index_country === -1) {
                this.item_filter_country.push(country);
            }

            for (let values_key in agency) {
                const index = this.item_filter_agency.indexOf(agency[values_key]);
                if (index > -1) {
                    this.item_filter_agency.splice(index, 1);
                } else if(agency[values_key]){
                    this.item_filter_agency.push(agency[values_key]);
                }
            }
        },
        update_country(){
            for(let data of this.item_filter_agency){
                for (let key in this.groupAgency) {
                    for (let item_agency in this.groupAgency[key]) {
                        if(this.groupAgency[key][item_agency].agency.includes(data)){
                            if(!this.item_filter_country.includes(item_agency)){
                                this.item_filter_country.push(item_agency)
                                break
                            }
                        }
                    }
                }
            }
        }
    },
    computed: {
        data(){
            return this.$store.getters['report/data'];
        },
        orderColumn(){
            return this.$store.getters['report/column'];
        },   
        default_column(){
            return this.$store.getters['report/default_column'];
        }, 
        type(){
            return this.$store.getters['report/type'] 
        },
        reset_column(){
            return this.$store.getters['report/reset_column']                       
        }, 
        all_name(){
            return this.$store.getters['report/all_name'];
        }, 
        all_indexation(){
            return this.$store.getters['report/all_indexation'];
        }, 
        accessAgency(){
            return this.$store.getters['result/access_agency']
        },
        groupAgency(){
            return this.$store.getters['agency/groupAgency'] 
        },
        select_export: {
            set(val){ this.$store.dispatch("report/select_export", {key: 'listID', value: val}) },
            get(){ 
                if(this.$store.getters['report/select_export'].checkAll){
                    return true
                }else{
                    return this.$store.getters['report/select_export'] ? this.$store.getters['report/select_export'].listID : null
                }
            }
        }, 
        select_export_obj(){
            return this.$store.getters['report/select_export']
        }, 
        select_all_name: {
            get: function () {
                return this.item_filter_name.length == this.all_name.length ? true : false;
            },
            set: function (value) {
                let selected = [];

                if (value) {
                    this.all_name.forEach(function (val) {
                        selected.push(val);
                    });
                }
                this.item_filter_name = selected;
            }
        },
        select_all_indexation: {
            get: function () {
                return this.item_filter_indexation.length == this.all_indexation.length ? true : false;
            },
            set: function (value) {
                let selected = [];

                if (value) {
                    this.all_indexation.forEach(function (val) {
                        selected.push(val);
                    });
                }
                this.item_filter_indexation = selected;
            }
        },
        select_all_agency:{
            get: function () {
                let temp = [];
                Object.values(this.groupAgency).forEach(agency=>{
                    Object.values(agency).forEach(function (val) {
                        temp.push(val.agency);
                    });
                });
                return this.item_filter_agency.length == temp.length ? true : false;
            },
            set: function (value) {
                let selected = [];

                if (value) {
                    Object.values(this.groupAgency).forEach(agency=>{
                        Object.keys(agency).forEach(function (val) {
                            selected.push(val);
                        });
                    });
                }
                this.item_filter_country = selected;
            }
        }
    },
    watch : { 
        all_name: function(newData, oldData) {
            if(oldData.length === this.item_filter_name.length){
                let selected = [];
                newData.forEach(function (val) {
                    selected.push(val);
                });
                this.item_filter_name = selected;
            }            
        },
        all_indexation: function(newData, oldData) {
            if(oldData.length === this.item_filter_indexation.length){
                let selected = [];
                newData.forEach(function (val) {
                    selected.push(val);
                });
                this.item_filter_indexation = selected;
            }            
        },
        groupAgency(){
            this.update_country()
        },
        item_filter_agency(){
            this.update_country()
        }
    },
    updated() {
        console.log("updated")
    },
    unmounted () {
        console.log("unmounted")
    }
}
</script>

<style scoped> 
.box-fix-left{ padding-top:133px}

/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 60vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}

.box_report_agency label {
    cursor: pointer;
    font-weight: 700;
    display: flex;
    align-items: baseline;
    margin: 0;
}
.box_report_agency div {
    position: relative;
    display: inline-block;
    font-weight: lighter;
    font-size: 11px;
    top: -2px;
}
.box_report_agency li{
    list-style: none
}
.list-checkbox{
    height: auto;
}
.box_report_agency .flag {
    margin-right: 5px;
    top: 3px;
}

.text-title-collapse {
    font-size: 14px;
    font-weight: bold;
    color: #ff6300; 
    display: inline-block;
    vertical-align: middle;
    width: 83%; 
    padding-left: 5px;
}
.bt-icon-down{
    color: #ff6300;
    float: right;
}
.box-border-gray-collapse {
    padding: 10px 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    color: #212529;
    text-align: left;
    border: solid 1px #ddd;
    border-radius: 17px;
}  
.list-li-item {
    padding: 5px 8px 4px 8px !important;
    font-size: 13px;
    background: #fbfbff;
    border-bottom: none;
    margin-bottom: 5px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(187, 195, 208, 0.5);
    list-style: none;
}
.list-li-item label{
    margin-bottom: 0;
}
.checkbox-list-li {
    list-style: none;
    padding: 0;
    border-bottom: none;
    margin-bottom: 7px;
}
.group-checkbox div{vertical-align: middle}

.title-report-filter{
    padding: 7px 12px;
    margin-left: 15px;
    font-size: 1.1rem;
    font-weight: bold;
}

</style>
 